import { useQuery } from "@apollo/client"
import { Link } from "gatsby"
import React, { useState } from "react"
import { ConfirmationStage } from "../components/ConfirmationStage/ConfirmationStage"
import { CartContentsList } from "../components/ShoppingCart/ShoppingCart"
import { GET_ACTIVE_ORDER } from "../components/ShoppingCart/ShoppingCart.vendure"
import { SignInStage } from "../components/SignInStage/SignInStage"
import Layout from "../layouts/RootLayout"

function useProgress(initialStage = 0) {
  const [currentStage, setStage] = useState(initialStage)
  return {
    currentStage,
    setStage,
    nextStage: () => currentStage < 3 && setStage(currentStage + 1),
    prevStage: () => currentStage > 0 && setStage(currentStage - 1),
  }
}

const CheckoutPage = () => {
  const { data, error, loading } = useQuery(GET_ACTIVE_ORDER)
  const { currentStage, setStage, nextStage, prevStage } = useProgress()
  // useEffect(() => {
  //   if (data.activeOrder && data.activeOrder.state === "ArrangingPayment") {
  //     setStage(2)
  //   }
  // }, [data])
  if (loading) {
    return (
      <Layout>
        <div className="bg-secondary">
          <div className="container py-5 mb-lg-3">
            <div className="row justify-content-center pt-lg-4 text-center">
              <div className="col-lg-5 col-md-7 col-sm-9">
                <h1 className="display-404 py-lg-3">...</h1>
                <h2 className="h3 mb-4">Un instant s'il vous plait</h2>
                <p className="fs-md mb-4">
                  <u>Voici quelques liens utiles à la place :</u>
                </p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-10">
                <div className="row">
                  <div className="col-sm-4 mb-3">
                    <Link className="card h-100 border-0 shadow-sm" to="/">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <i className="czi-home text-primary h4 mb-0 mr-3"></i>
                          <div className="ps-3">
                            <h5 className="fs-sm mb-0">Accueil</h5>
                            <span className="text-muted fs-ms">
                              Retourner à l'accueil
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-sm-4 mb-3">
                    <Link
                      className="card h-100 border-0 shadow-sm"
                      to="/products"
                    >
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <i className="czi-search text-success h4 mb-0 mr-3"></i>
                          <div className="ps-3">
                            <h5 className="fs-sm mb-0">Boutique</h5>
                            <span className="text-muted fs-ms">
                              Voir nos articles
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-sm-4 mb-3">
                    <Link
                      className="card h-100 border-0 shadow-sm"
                      to="/#contact"
                    >
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <i className="czi-help text-info h4 mb-0 mr-3"></i>
                          <div className="ps-3">
                            <h5 className="fs-sm mb-0">Aide &amp; Support</h5>
                            <span className="text-muted fs-ms">
                              Nous envoyer un message
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
  if (error || !data.activeOrder) {
    return (
      <Layout>
        <div className="bg-secondary">
          <div className="container py-5 mb-lg-3">
            <div className="row justify-content-center pt-lg-4 text-center">
              <div className="col-lg-5 col-md-7 col-sm-9">
                <h1 className="display-404 py-lg-3">:(</h1>
                <h2 className="h3 mb-4">
                  {error ? error.message : "Votre liste est vide"}
                </h2>
                <p className="fs-md mb-4">
                  <u>Voici quelques liens utiles à la place :</u>
                </p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-10">
                <div className="row">
                  <div className="col-sm-4 mb-3">
                    <Link className="card h-100 border-0 shadow-sm" to="/">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <i className="czi-home text-primary h4 mb-0 mr-3"></i>
                          <div className="ps-3">
                            <h5 className="fs-sm mb-0">Accueil</h5>
                            <span className="text-muted fs-ms">
                              Retourner à l'accueil
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-sm-4 mb-3">
                    <Link
                      className="card h-100 border-0 shadow-sm"
                      to="/products"
                    >
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <i className="czi-search text-success h4 mb-0 mr-3"></i>
                          <div className="ps-3">
                            <h5 className="fs-sm mb-0">Boutique</h5>
                            <span className="text-muted fs-ms">
                              Voir nos articles
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-sm-4 mb-3">
                    <Link
                      className="card h-100 border-0 shadow-sm"
                      to="/#contact"
                    >
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <i className="czi-help text-info h4 mb-0 mr-3"></i>
                          <div className="ps-3">
                            <h5 className="fs-sm mb-0">Aide &amp; Support</h5>
                            <span className="text-muted fs-ms">
                              Nous envoyer un message
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
  const stages = [
    {
      name: "Mes informations",
      render: () => <SignInStage nextStage={nextStage} />,
    },
    {
      name: "Confirmation",
      render: () => (
        <ConfirmationStage nextStage={nextStage} prevStage={prevStage} />
      ),
    },
  ]
  return (
    <Layout>
      <div className="page-title-overlap bg-dark pt-4">
        <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
          <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                <li className="breadcrumb-item">
                  <Link className="text-nowrap" to="/">
                    <i className="ci-home"></i>Accueil
                  </Link>
                </li>
                <li className="breadcrumb-item text-nowrap">
                  <Link to="/products">Boutique</Link>
                </li>
                <li
                  className="breadcrumb-item text-nowrap active"
                  aria-current="page"
                >
                  Ma liste
                </li>
              </ol>
            </nav>
          </div>
          <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
            <h1 className="h3 text-light mb-0">Mes informations</h1>
          </div>
        </div>
      </div>

      <div className="container pb-5 mb-2 mb-md-4">
        <div className="row">
          <section className="col-lg-8">
            <div className="steps steps-light pt-2 pb-3 mb-5">
              {stages.map((stage, index) => (
                <a
                  className={`step-item  ${
                    index === currentStage ? "active current" : ""
                  }`}
                  key={stage.name}
                >
                  <div className="step-progress">
                    <span className="step-count">{index + 1}</span>
                  </div>
                  <div className="step-label">
                    <i className="ci-user-circle"></i>
                    {stage.name}
                  </div>
                </a>
              ))}
            </div>

            {stages[currentStage].render()}
          </section>
          <CartContentsList order={data.activeOrder} />
        </div>
      </div>
    </Layout>
  )
}

export default CheckoutPage
