import { useMutation, useQuery } from "@apollo/client"
import { Formik } from "formik"
import { Link } from "gatsby"
import React from "react"
import {
  ADD_ADDRESS_TO_ORDER,
  GET_ACTIVE_ORDER_FOR_CHECKOUT,
  SET_ORDER_CUSTOMER,
} from "../../pages/checkout.vendure"

export function SignInStage({ nextStage }) {
  const { data, loading, error } = useQuery(GET_ACTIVE_ORDER_FOR_CHECKOUT, {
    fetchPolicy: "network-only",
  })
  const [setOrderCustomer] = useMutation(SET_ORDER_CUSTOMER)
  const [addAddressToOrder] = useMutation(ADD_ADDRESS_TO_ORDER)
  if (loading) {
    return <div>Chargement...</div>
  }
  if (error || !data.activeOrder) {
    return <div>Error! {error ? error.message : "No active order"}</div>
  }
  return (
    <>
      <Formik
        onSubmit={({ __typename, id, ...formData }) => {
          let customerInfos = Object.assign({}, formData)
          delete customerInfos.streetLine1
          setOrderCustomer({
            variables: {
              input: customerInfos,
            },
          })
            .then(() => {
              addAddressToOrder({
                variables: {
                  input: {
                    streetLine1: formData.streetLine1,
                    countryCode: "FR",
                  },
                },
              })
            })
            .then(() => nextStage())
        }}
        initialValues={
          data.activeOrder.customer || {
            firstName: "",
            lastName: "",
            emailAddress: "",
            phoneNumber: "",
          }
        }
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="checkout-fn">
                      Prénom
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="checkout-fn"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="firstName"
                      value={values.firstName}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="checkout-ln">
                      Nom
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="checkout-ln"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="lastName"
                      value={values.lastName}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="checkout-email">
                      E-mail
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      id="checkout-email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="emailAddress"
                      value={values.emailAddress}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-3">
                    <label
                      className="form-label"
                      htmlFor="checkout-phoneNumber"
                    >
                      Numéro téléphone
                    </label>
                    <input
                      className="form-control"
                      type="phoneNumber"
                      id="checkout-phoneNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="phoneNumber"
                      value={values.phoneNumber}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <div className="mb-6">
                    <label
                      className="form-label"
                      htmlFor="checkout-streetLine1"
                    >
                      Adresse
                    </label>
                    <input
                      className="form-control"
                      type="streetLine1"
                      id="checkout-streetLine1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="streetLine1"
                      value={values.streetLine1}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
              <div className="col-sm-6">
                <div className="w-50 pe-3">
                  <Link
                    className="btn btn-secondary d-block w-100"
                    to="/products"
                  >
                    <i className="ci-arrow-left mt-sm-0 me-1"></i>
                    <span className="d-none d-sm-inline">
                      Retour vers la boutique
                    </span>
                    <span className="d-inline d-sm-none">Retour</span>
                  </Link>
                </div>

                <div className="w-50 ps-2 pl-2">
                  <button
                    className="btn btn-primary d-block w-100"
                    type="submit"
                  >
                    <span className="d-none d-sm-inline">
                      Valider mes informations
                    </span>
                    <span className="d-inline d-sm-none">Suivant</span>
                    <i className="ci-arrow-right mt-sm-0 ms-1"></i>
                  </button>
                </div>
              </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </>
  )
}
