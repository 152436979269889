import { useMutation, useQuery } from "@apollo/client"
import { BuilderComponent } from "@builder.io/react"
import { Formik } from "formik"
import { graphql, StaticQuery } from "gatsby"
import React from "react"
import { GET_ACTIVE_ORDER } from "../../components/ShoppingCart/ShoppingCart.vendure"
import {
  ADD_PAYMENT_TO_ORDER,
  TRANSITION_TO_ARRANGING_PAYMENT,
} from "../../pages/checkout.vendure"

const query = graphql`
  query {
    allBuilderModels {
      wishlist(limit: 1, options: { cachebust: true }) {
        content
      }
    }
  }
`

export function ConfirmationStage({ nextStage, prevStage }) {
  const { data, error, loading } = useQuery(GET_ACTIVE_ORDER)

  const [transitionToArrangingPayment] = useMutation(
    TRANSITION_TO_ARRANGING_PAYMENT
  )

  const [addPaymentToOrder] = useMutation(ADD_PAYMENT_TO_ORDER)

  const [inputs, setInputs] = React.useState({ confirmationMessage: "" })

  return (
    <div>
      <StaticQuery query={query}>
        {data => {
          const models = data.allBuilderModels
          const wishlist = models.wishlist[0].content
          return <BuilderComponent name="wishlist" content={wishlist} />
        }}
      </StaticQuery>

      <p>
        {" "}
        <span className="text-muted">Référence: </span> {data.activeOrder.code}
      </p>
      {inputs.confirmationMessage}

      {inputs.confirmationMessage.length == 0 && (
        <Formik
          onSubmit={({ __typename, id, ...formData }) => {
            transitionToArrangingPayment().then(() =>
              addPaymentToOrder({
                variables: {
                  input: {
                    method: "example-payment-provider",
                    metadata: {
                      message: "Pas de paiement car liste de souhaits",
                    },
                  },
                },
              }).then(() => {
                setInputs({
                  confirmationMessage:
                    "Votre demande est enregistrée, nous allons vous contacter prochainement.",
                })
              })
            )
          }}
          initialValues={{}}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="w-50 pe-3">
                    <button
                      className="btn btn-secondary d-block w-100"
                      onClick={prevStage}
                    >
                      <i className="ci-arrow-left mt-sm-0 me-1"></i>
                      <span className="d-none d-sm-inline">
                        Modifier mes informations
                      </span>
                      <span className="d-inline d-sm-none">Retour</span>
                    </button>
                  </div>

                  <div className="w-50 ps-2 pl-2">
                    <button
                      className="btn btn-primary d-block w-100"
                      type="submit"
                    >
                      <span className="d-none d-sm-inline">
                        Je comprends et je confirme ma liste
                      </span>
                      <span className="d-inline d-sm-none">Suivant</span>
                      <i className="ci-arrow-right mt-sm-0 ms-1"></i>
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
        </Formik>
      )}
    </div>
  )
}
